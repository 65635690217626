import React from "react"
import { useTheme } from "emotion-theming"
import Layout from "../../../components/Layout"
import "../../../styles/global.css"
import { Section } from "../../../components/PageComponents/Section"
import SEO from "../../../components/seo"
import { Link } from "gatsby"
import styled from "@emotion/styled"

const HaskellDeveloperPage = () => {
  const theme = useTheme()

  return (
    <Layout>
      <SEO
        title="evoli - evoli are looking for a Haskell developer"
        noIndex={true}
      />
      <Section>
        <ContentContainer>
          <h3>evoli are looking for a Haskell developer</h3>
          <p>
            evoli is a company with a focus on insurtech that challenges the
            Swedish insurance industry with affordable insurance to a carefully
            selected target group. Among the founders are both experienced
            entrepreneurs, IT developers and people with specialist knowledge in
            insurance. At evoli, the working day is eventful, and we are a tight
            team that has fun together.
            <br />
            <br />
            We are now looking for a Haskell developer who is not afraid of a
            challenge. Someone that will help with daily operations, maintaining
            and developing our platform. We have an agile way of working and
            combine working remotely with days together at our office in central
            Stockholm.
          </p>
          <strong>Your daily work will involve:</strong>
          <ul>
            <li>Working with tech like Haskell, GCP, PostgreSQL.</li>
            <li>Working with a microservice architecture.</li>
            <li>
              Collaborate with the PMO, data scientists, and other developers in
              the team on different products.
            </li>
          </ul>

          <strong>Experience:</strong>
          <ul>
            <li>1+ years of experience working with Haskell.</li>
            <li>
              Knowledge in PostgreSQL, Docker and GCP. Basic understanding of
              Linux.
            </li>
            <li>Experience in GKE</li>
            <li>
              Experience in one or more of the following languages is a plus:
              Javascript, Java, or Python.
            </li>
          </ul>

          <strong>In addition, you:</strong>
          <ul>
            <li>Communicates fluently in English (we also speak Swedish)</li>
            <li>
              Enjoys working in a team environment, sharing knowledge and ideas
              with colleagues.
            </li>
          </ul>

          <p>
            Submit your application as soon as possible to our head of PMO,{" "}
            <a href="Petronella@evoli.se">Petronella@evoli.se</a>.<br /> We are
            processing applications continuously as we wish to fill the position
            quickly.
          </p>
        </ContentContainer>
      </Section>
    </Layout>
  )
}

export default HaskellDeveloperPage

const ContentContainer = styled.section`
  color: #292944;
  font-size: 14px;
  max-width: 600px;

  ul {
    margin-top: 5px;
  }
  li {
    margin-bottom: 2px;
  }

  h4 {
    font-weight: 500;
    margin-bottom: 10px;
  }
  h3 {
    font-weight: 500;
  }

  strong {
    font-weight: 500;
  }
  a {
    color: #5558ea;
    font-weight: 500;
    :hover {
      text-decoration: underline;
    }
  }
`
